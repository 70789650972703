@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Regular.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Bold.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Bold.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Medium.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  font-family: YekanBakh;
}

body {
  direction: rtl;
  font-family: "YekanBakh", sans-serif !important;
  padding: 0;
  margin: 0;
  background: #fff;
  color: #121212;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100% !important;
}

.p-5 {
  padding: 5px !important;
}

.bg-purple {
  background: #B8B0FF !important;
}

.bg-inside-purple {
  background: #8276F1 !important;
}

.service.active {
  background: #F4F6F8 !important;
}

.service.active .service-name {
  color: #000 !important;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.bg-inside-purple.active {
  background: #F4F6F8 !important;
  color: #000 !important;
  animation: bounce 0.7s ease-in-out;
  transition: background-color 0.8s ease, color 0.8s ease;
  height: 192px;

}

.bg-button-purple {
  background: #8276F1 !important;
}

.bg-button-black {
  background: #121212 !important
}

.fs-12 {
  font-size: 12px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fw-700 {
  font-weight: 700;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.text-white {
  color: #fff;
}

.text-gray {
  color: #B0B0B0;

}

.d-flex {
  display: flex
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.overflow-overlay {
  overflow: overlay;
}

.overflow-overlay::-webkit-scrollbar {
  display: none;
}

.header {
  width: auto;
  height: 294px;
  position: sticky;
  top: 0;
}

.header .container {
  margin: 0 72px;
}

.service {
  padding: 5px;
  border-radius: 20px;
  margin-left: 52px;
}

.service-name {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.service-list-button {
  width: 100%;
  padding: 20px 24px 16px 24px;
  border-radius: 100px;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;

}

/* style={{position:'absolute', bottom: 0}} */

.service-list {
  display: flex;
  width: 720px;
}

.service-list .container {
  display: flex;
  flex-direction: row;
  margin: 24px 32px 0 32px;
  justify-content: space-between;
  align-content:flex-start
}

.service-list .box {
  background: #F5F5F5;
  padding: 16px;
  border-radius: 24px;
  margin-bottom: 24px;
}

.factor {
  background: #F5F5F5;
  width: 360px;
  position: sticky;
  top: 294px;
  height: 84vh;
}

.factor .container {
  padding: 24px;
}

.factor .title {
  font-size: 24px;
  font-weight: 700;
}

.factor img {
  width: 120px;
  height: 120px;
}

.delete-img {
  width: 48px !important;
  height: 48px !important;
  margin-right: 32px;
}

.print-page {
  display: none;
}

@media print {
  .print-page {
    display: flex;
    font-size: 12px !important;
    font-weight: 700;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .print-page h2 {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
  }

  /* table {
    width: 360px;
    font-size: 9px;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 2px;
    text-align: center;
    border: 1px solid #ddd;
  }

  th:first-child {
    width: 10px;
  }

  th {
    background-color: #f2f2f2;
  }

  .title-factor {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .total {
    font-weight: bold;
  } */


  .table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    border-radius: 15px;
    padding: 3px;
  }
  
  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 11px !important;
  }
  
  .title {}
  
  .price {
    text-align: right;
  }
  
  .total-row {
    padding-top: 5px;
    font-weight: bold;
    border-top: 1px solid #000;
  }
  
  .barcode {
    text-align: right;
  }

}
